*,
*::after,
*::before {
    box-sizing: border-box;
}


.link {
    cursor: pointer;
    font-size: 18px;
    position: relative;
    white-space: nowrap;
    /* color: var(--color-text); */
}

.link::before,
.link::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 140%;
    left: 0;
    pointer-events: none;
}

.link.link--iocaste {
    &::before {
        display: none;
    }
}

.link::before {
    content: '';
    /* show by default */
}

.link--metis {
    font-family: bely-display, sans-serif;
    text-transform: lowercase;
}

.link--metis::before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
    color: $main-color;
}

.link--metis:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
}


.link--iocaste {
    font-family: lust-fine, sans-serif;
    overflow: hidden;
    padding: 6px 0;
}

.cases__slide-link-coming {
    .link__graphic {
        stroke: $main-color;
    }
}

.link__graphic {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    fill: none;
    stroke: #84AC6C;
    stroke-width: 1px;
}

.link__graphic--slide {
    top: -3px;
    stroke-width: 2px;
    transition: transform 0.7s;
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.link:hover .link__graphic--slide {
    transform: translate3d(-66.6%, 0, 0);
}


.link__graphic--stroke path {
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
}

.link:hover .link__graphic--stroke path {
    stroke-dashoffset: 0;
}




.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}



.button--bestia {
	color: #fff;
	background: #fff;
	padding: 0;
}
.button-white.button--bestia {
    background: inherit;
}

.button--bestia .button__bg {
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background: #111;
	border-radius: 71px;
	overflow: hidden;
	transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.button--bestia:hover .button__bg {
	transform: scale3d(1,1,1);
}

.button--bestia span {
    color: #fff;
}
.button--bestia:hover span {
    color: #000;
}
.button--bestia svg path{
    fill: #fff;
} 

.button--bestia.button-black svg path{
    fill: #fff;
} 



.button--bestia .button__bg::before,
.button--bestia .button__bg::after {
    content: '';
    position: absolute;
    background: #fffff0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale3d(0, 0, 1);
    transform-origin: center center;
}

.button--bestia .button__bg::before {
    border-radius: 50%;
    z-index: -1;
}

.button--bestia .button__bg::after {
    border-radius: 71px;
    z-index: -2;
}

.button--bestia .button__bg::before {
	width: 110%;
	height: 0;
	padding-bottom: 110%;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	transform: translate3d(-50%,-50%,0) scale3d(0,0,1);
}

.button--bestia:hover .button__bg::before,
.button--bestia:hover .button__bg::after {
    transform: scale3d(1, 1, 1);
}

.button--bestia:hover .button__bg::before {
	transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
	transform: translate3d(-50%,-50%,0) scale3d(1,1,1);
}

.button--bestia:hover svg path {
    fill: #000;
}

.button-case {
    &.button--bestia {
        svg path {
            fill: #333;
        }
        span {
            color: #333;
        }
        .button__bg {
            background: #fff;
            &::before, &::after {
                background-color: #333;
            }
        }
        &:hover {
            span {
                color: #ffffff;
            }
            svg path {
                fill: #ffffff;
            }
        }
    }
}

.button-white {
    &.button--bestia {
        svg path {
            fill: #333;
        }
        span {
            color: #333;
        }
        .button__bg {
            background: unset;
            &::before, &::after {
                background-color: #333;
            }
        }
        &:hover {
            span {
                color: #fffff0;
            }
            svg path {
                fill: #fffff0;
            }
        }
    }
}

.button--bestia .button__bg::after {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.3s;
}

.button--bestia:hover .button__bg::after {
	opacity: 1;
	transition-duration: 0.01s;
	transition-delay: 0.3s;
}

.button--bestia span {
	display: block;
	position: relative;
    z-index: 1;
}
