@import "variables";
@import "normalize.css/normalize.css";
@import "fonts";

*, :after, :before {
  box-sizing: border-box;
}

:root {
  --app-height: 100%;
}
html {
  // scroll-behavior: smooth;
}
body {
  font-family: 'Benzin', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #CCCCCC;
  background: #fffff0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  color: #FFFFFF;
  margin: 0;
}
h1, h2 {
  font-size: 48px;
  line-height: 56px;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 48px;
  }
}
p {
  margin: 0;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
  color: #EAEAEA;
  transition: .3s;

}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
video {
  max-width: 100%;
  width: 100%;
  display: block;
}

.app {
  position: relative;
  background: #fffff0;
}

.app section {
  @media only screen and (max-height: 576px) and (orientation: landscape) {
    height: auto;
  }
}

.page-name {
  display: flex;
  align-items: center;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: $main-color;
    white-space: nowrap;
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
  &__line {
    position: relative;
    width: 100%;
    height: 1px;
    background: rgba(51, 51, 51, 0.15);
    margin-left: 40px;
    img {
      position: absolute;
      max-width: 216px;
      right: auto;
      top: 0;
      transform: translateY(-50%);
      @media (max-width: 576px) {
        max-width: 140px;
      }
    }
    &-scrolled {
      img {
        position: relative;
        right: unset;
        top: unset;
        transform: unset;
      }
      .page-name__line-wrapper {
        position: absolute;
        right: auto;
        width: 100%;
        top: 0;
        transform: translateY(calc(-50% + 1px));
        overflow: hidden;
      }
      .page-name__line-inner {
        transition: all .3s ease-in-out;
        transform: translateZ(0);
        will-change: transform;
        backface-visibility: hidden;
      }
    }
  }
}

.links {
  display: none;
  // display: flex;
  align-items: center;
  a {
    display: flex;
    &:nth-child(2) {
        margin: 0 32px;
    }
  }
  svg {
      width: 24px;
      height: 24px;
      transition: all .3s ease;
      & path {
        fill: $main-color;
      }
      &:hover {
        transform: scale(1.2);
      }
  }
}

.video {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video__overlay {
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../assets/img/dot_bg.png) left top repeat;
}

@media (max-width: 767px) {
  .video {
    display: none;
  }
}

.header:has(.header+.aboutUs){
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%)
}

video {
  object-fit: cover;
}

.preloader {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100vh;
  background-color: #020000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  pointer-events: none;
  top: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #fffff0;
  @media (max-width: 767px) {
    display: none;
  }
}
.link-disable {
  pointer-events: none;
}


@import 'base';
@import 'components/header';
@import 'components/button';
@import 'pages/home';
@import 'pages/about';
@import 'pages/cases';
@import 'pages/directions';
@import 'pages/contacts';
@import 'pages/case';
@import 'pages/about-us';
@import 'components/cookies';
@import 'components/toggle-switch';
@import 'pages/terms'
