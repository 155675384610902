
@font-face {
  font-family: 'Benzin';
  src: local('Benzin Regular'), 
  url('../assets/fonts/Benzin/Benzin-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Benzin';
  src: local('Benzin Medium'), 
  url('../assets/fonts/Benzin/Benzin-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Benzin';
  src: local('Benzin Semibold'), 
  url('../assets/fonts/Benzin/Benzin-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), 
  url('../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter ExtraBold'), 
  url('../assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Source Code Pro';
  src: local('Source Code Pro'), 
  url('../assets/fonts/SourceCode/SourceCodePro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

