.cases {
    padding-top: $header-desktop-height;
    height: var(--app-height);
    background: #fffff0;
    position: relative;
    z-index: 2;
    @media (max-width: 767px) {
        padding-top: $header-mobile-height;
    }
    @media (max-width: 767px) and (max-height: 732px){
        height: auto;
    }
    &__wrapper {
        height: 100%;
        padding: 54px 48px 102px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 1660px) {
            padding: 30px 48px 54px;
        }
        @media (max-width: 991px) {
            padding: 24px 48px 24px;
        }
        @media (max-width: 576px) {
            padding: 24px;
            justify-content: unset;
        }
    }
    &__line {
        // margin-top: 54px;
    }
    &__slides {
        margin-top: 90px;
        flex: 1;
        overflow: hidden;
        position: relative;
        width: 100%;
        touch-action: pan-y;
        @media (max-width: 576px) {
            margin-top: 42px;
        }
        .swiper {
            height: 100%;
            width: 70%;
            margin-left: unset;
            margin-right: unset;
            overflow: visible!important;
            @media (max-width: 991px) {
                width: 100%;
            }
        }
        
        .swiper-slide {
            transform-origin: left bottom;
            height: auto;
            .cases__slide-overlay {
                pointer-events: all;
                // background: linear-gradient(270deg, #FEF6E6 0%, rgba(255, 255, 240, 0.00) 100%);
                backdrop-filter: blur(1px);
                transition: all .3s;
                @media (max-width: 767px) {
                    display: none;
                }
            }
        }
        .swiper-slide.swiper-slide-active {
            transition: all .8s;
            .cases__slide-overlay {
                pointer-events: none;
                backdrop-filter: none;
                // background: none;
            }
        }
        &-arrows {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 3;
            &-left, &-right {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 68px;
                height: 68px;
                border-radius: 100%;
                transition: all .3s ease;
                &:hover {
                    background: rgba(255, 255, 255, 0.35);
                }
                @media (max-width: 767px) {
                    width: 32px;
                    height: 32px;
                    &:hover {
                        background: unset;
                    }
                }
            }
            &-right {
                @media (max-width: 576px) {
                    margin-left: 16px;
                }
            }
            @media (max-width: 576px) {
               top: -8px;
            }
            svg {
                cursor: pointer;
                width: 34px;
                & path {
                    fill: $main-color;
                }
                // height: 48px;
                @media (max-width: 767px) {
                    width: 18px;
                    // height: 32px;
                }
                &:last-child {
                    // @media (max-width: 576px) {
                    //     margin-left: 16px;
                    // }
                }
            }
        }
    }
    &__slide {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        padding-right: 150px;
        @media (max-width: 991px) {
            padding-right: unset;
        }
        // max-width: 1057px;
        &-num {
            font-family: 'Benzin';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 125%;;
            color: $main-color;
            @media (max-width: 576px) {
                font-size: 14px;
                line-height: 125%;
            }
        }
        &-top {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 576px) {
                margin-top: 32px;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &-wrap {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media (max-width: 576px) {
                justify-content: space-between;
                margin-top: 22px;
                width: 100%;
            }
        }

        &-title {
            font-family: 'Benzin';
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 125%;
            color: $secondary-color;
            mix-blend-mode: normal;
            @media (max-width: 991px) {
                font-size: 40px;
            }
            @media (max-width: 767px) {
                font-size: 36px;
            }
            @media (max-width: 767px) {
                font-size: 32px;
            }
        }
        &-link {
            font-family: 'Benzin';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 125%;
            display: flex;
            align-items: flex-end;
            text-align: right;
            // text-decoration-line: underline;
            color: #84AC6C;
            &-coming {
                color: $main-color;
                cursor: default;
            }
            @media (max-width: 767px) {
                font-size: 16px;
            }
            @media (max-width: 576px) {
                font-size: 14px;
            }
        }
        &-subtitle {
            margin-top: 32px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 200%;
            color: $main-color;
            mix-blend-mode: normal;
            @media (max-width: 576px) {
                margin-top: 24px;
                font-size: 14px;
                line-height: 32px;
            }
        }
        &-picture {
            position: relative;
            min-height: 161px;
            display: flex;
            align-items: flex-end;
            margin-top: 40px;
            padding: 24px;
            flex: 1;
            @media (max-width: 576px) {
                margin-top: 24px;
                padding: 14px;
            }
        }
        &-bottom {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            z-index: 2;
            @media (max-width: 576px) {
                justify-content: center;
            }
        }
        &-data {
            display: flex;
            align-items: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 102.5%;
            text-transform: uppercase;
            color: #fffff0;
            @media (max-width: 767px) {
                display: none;
            }
            &-mob {
                display: none;
                font-size: 12px;
                span {
                    color: $main-color;
                    padding: 7px 11px;
                    border: 1px solid $main-color;
                    border-radius: 50px;
                    &:first-child {
                        margin-right: 8px;
                    }
                }
                @media (max-width: 576px) {
                    display: flex;
                }
            }
        }
        &-line {
            margin: 0 8px;
            width: 16px;
            height: 1px;
            background-color: $line-color;
            @media (max-width: 767px) {
                display: none;
            }
        }
        &-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.34);
            border: 1px solid rgba(255, 255, 255, 0.13);
        }
        &-blur {
            position: absolute;
            z-index: 3;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

