.header {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    height: $header-desktop-height;
    width: 100%;
    z-index: 10;
    background: unset;
    transition: background-color .3s ease;

    @media (max-width: 767px) {
        background: $header-background;
        height: $header-mobile-height;
    }
    @media (orientation: landscape) and (max-height: 650px) {
        background: $header-background;
    }
    &__background {
        &-gradient {
            background: $header-background
        }
        &-black {
            background: #020000;
            @media (max-width: 767px) {
                background: #020000;
            }
        }
    }
    &__wrapper {
        position: relative;
        padding: 0 47px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: 576px) {
            padding: 0 24px;
        }

    }
    &__logo {
        display: flex;
        z-index: 1;
        cursor: pointer;
        @media (max-width: 767px) {
            display: none;
        }
        svg {
            width: 128px;
            & * path{
                fill: $main-color;
            }
        }
        &-mobile {
            display: none;
            @media (max-width: 767px) {
                display: flex;
            }
        }
    }
    &__line {
        flex: 1;
        padding: 0 56px;
        @media (max-width: 1199px) {
            padding: 0 40px;
        }
        @media (max-width: 991px) {
            display: none;
        }
        &-small {
            max-width: 202px;
            width: 100%;
            .header__line-block {
                width: 100%;
            }
        }
        &-block {
            height: 1px;
            background-color: $line-color;
        }
    }
    &__nav {
        @media (max-width: 767px) { 
            display: none;
        }
        &-mobile {
            display: none;
            @media (max-width: 767px) {
                &-active {
                    display: block;
                }
                position: fixed;
                top: $header-desktop-height;
                left: 0;
                padding: 58px 48px 84px;
                width: 100%;
                background: #fffff0;
                height: calc(100vh - $header-desktop-height);
                overflow: auto;
            }
            @media (max-width: 767px) {
                top: 0;
                padding: calc(58px + $header-mobile-height) 48px 84px;
                height: calc(100vh);
            }
            @media (max-width: 576px) {
                top: 0;
                padding: calc(58px + $header-mobile-height) 24px 84px;
            }
            & .header__language {
                display: block;
            }
        }
        &-links {
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        &-link {
            margin-left: 72px;
            @media (max-width: 1199px) {
                margin-left: 40px;
            }
            @media (max-width: 991px) {
                margin-left: 15px;
            }
            @media (max-width: 767px) {
                margin-left: unset;
                margin-bottom: 40px;
            }
            &:first-child {
                margin-left: unset;
            }
            &:last-child {
                margin-left: 56px;
                @media (max-width: 991px) {
                    margin-left: 20px;
                }
                @media (max-width: 767px) {
                    margin-left: unset;
                    margin-bottom: unset;
                }
                a {
                    font-size: 14px;
                    @media (max-width: 767px) {
                        font-size: 28px;
                    }
                }
            }
            div , a {
                cursor: pointer;
                text-transform: uppercase;
                font-family: 'Inter';
                // white-space: nowrap;
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                line-height: 102.5%;
                color: $main-color;
                @media (max-width: 1199px) {
                    font-size: 14px;
                }
                @media (max-width: 767px) {
                    font-family: 'Benzin';
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 39px;
                }
            }
        }
    }

    
&__hamburger {
    cursor: pointer;
    display: none;
    height: 18px;
    overflow: hidden;
    position: relative;
    transition: all .35s;
    width: 18px;
    z-index: 3;
    @media (max-width: 767px) {
        display: block;
    }
    &-line {
      background: $main-color;
      border-radius: 10px;
      display: block;
      height: 2px;
      left: 0;
      pointer-events: none;
      position: absolute;
      transition: all .35s;
      width: 100%;
      &_1 {
        top: 2px;
      }
      &_2 {
        top: 7px;
      }
      &_3 {
        top: 12px;
      }
    }
    &_active {
      .header__hamburger-line {
        &_1 {
          top: 6px;
          transform: rotate(-45deg);
        }
        &_2 {
          opacity: 0;
        }
        &_3 {
          top: 6px;
          transform: rotate(45deg);
        }
      }
    }
  }

    &__language {
        // display: none;
        position: relative;
        cursor: pointer;
        border-radius: 5px;
        transition: .3s ease;
        background-color: unset;
        &.active {
            background-color: rgba(217, 217, 217, 0.8);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            z-index: 11;
            .header__language-bottom {
                pointer-events: all;
                opacity: 1;
                display: block;
                background-color: rgba(217, 217, 217, 0.8);
            }
            .header__language-arrow {
                transform: rotate(180deg);
            }
            .header__language-item {
                transition: all .3s ease;
                &:hover {
                    background-color: #ffffff;
                }
            }
        }
        &-item {
            padding: 6px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 102.5%;
            display: grid;
            column-gap: 8px;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
            align-items: center;
            text-transform: uppercase;
            color: $main-color;
            img {
                width: 12px;
            }
        }
        &-arrow {
            transition: all .3s ease;
            & path {
                stroke: $main-color;
            }
        }
        &-top {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        &-bottom{
            pointer-events: none;
            opacity: 0;
            z-index: -1;
            width: 100%;
            position: absolute;
            left: 0;
            transition: all .3s ease;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .header__language-item {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
        &-top {
            img {
                width: 16px;
                height: 16px;
            }
        }
        @media (max-width: 767px) {
            display: none;
        }
        img {
            // width: 16px;
            // height: 16px;
            // margin-left: 8px;
        }
        &:hover {
            background: rgba(217, 217, 217, 0.8);
        }
    }
    &__mobile {
        &-bottom {
            display: none;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 84px;
            border-top: 1px solid $line-color;
            padding: 0 48px;
            background-color: #fffff0;
            @media (max-width: 767px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            @media (max-width: 576px) {
                padding: 0 24px;
            }
        }
    }
}
