.about {
    height: var(--app-height);
    // background: linear-gradient(64.96deg, #000000 22.24%, rgba(0, 0, 0, 0) 84.56%, rgba(0, 0, 0, 0) 84.56%);
    // background: linear-gradient(0deg, #FFFFF0 0%, rgba(255, 255, 240, 0.00) 36.36%);
    background: 
        // linear-gradient(39deg, #FFFFF0 0%, rgba(255, 255, 240, 0.00) 64.58%),
        // linear-gradient(0deg, rgba(255, 248, 231, 0.20) 0%, rgba(255, 255, 240, 0) 36.36%),
        // linear-gradient(0deg, rgba(255, 248, 231, 0.20) 0%, rgba(255, 255, 240, 0) 36.36%);
        linear-gradient(72.47deg, #FFFFF0 29.17%, rgba(255, 255, 240, 0) 63.72%),
        linear-gradient(0deg, #FFFFF0 0%, rgba(255, 255, 240, 0) 36.36%),
        linear-gradient(0deg, rgba(255, 248, 231, 0.2) 0%, rgba(255, 248, 231, 0) 36.36%);



    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
    padding: $header-desktop-height 48px 0 ;
    @media (max-width: 1600px) {
        padding-bottom: 48px;
        // justify-content: center;
    }
    @media (max-width: 576px) {
        padding: $header-mobile-height 24px 20px;
        justify-content: unset;
        // background: url(../../assets/img/about-bg-mob.jpg) center center/cover no-repeat;
    }
    &__bg {
        &-mob {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            @media (max-width: 767px) {
                display: block;
            }
        }
    }
    &__wrapper {
        max-width: 1057px;
        // padding-bottom: 236px;
        @media (max-width: 1600px) {
            padding-bottom: 0;
        }
        @media (max-width: 576px) {
            padding: 24px 0;
        }
    }
    &__title {
        margin-top: 55px;
        font-family: 'Benzin';
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 56px;
        color: $main-color;
        mix-blend-mode: normal;
        @media (max-width: 1440px) {
            font-size: 42px;
            line-height: 48px;
        }
        @media (max-width: 1199px) {
            font-size: 38px;
            line-height: 42px;
        }
        @media (max-width: 991px) {
            font-size: 34px;
            line-height: 40px;
        }
        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 36px;
        }
        @media (max-width: 567px) {
            margin-top: 77px;
            font-size: 24px;
            line-height: 125%;
        }
    }
    &__subtitle {
        margin-top: 40px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 40px;
        color: $secondary-color;
        mix-blend-mode: normal;
        @media (max-width: 1440px) {
            font-size: 18px;
            line-height: 32px;
        }
        @media (max-width: 991px) {
            font-size: 16px;
        }
        @media (max-width: 767px) {
            margin-top: 24px;
        }
        @media (max-width: 576px) {
            font-size: 14px;
        }
    }
    &__button {
        margin-top: 48px;
        display: inline-block;
        @media (max-width: 767px) {
            margin-top: 40px;
        }
    }
}    