.switch {
  position: relative;
  display: inline-block;
  border: 1px solid #FFF;
  border-radius: 10px;
  width: 45px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: #28262D;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 2px;
  &__on, &__off {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-transform: uppercase;
    color: #FFF;
    font-size: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 0;
  }
  &__on {
    left: 5px;
  }
  &__off {
    right: 4px;
  }
}

.slider:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 14px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 10px;
  z-index: 1;
}

input:checked + .slider {
  //background-color: #2196F3;
}


input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}