$header-mobile-height: 80px;
$header-desktop-height: 86px;

$cookies-desktop-height: 60px;
$cookies-mobile-height: 148px;



$line-color: rgba(51, 51, 51, 0.25);;

$main-color: #333333;
$secondary-color: #222222;

$header-background: linear-gradient(180deg, #fffff0 -43.37%, rgba(0, 0, 0, 0) 95.41%);
