.case {
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 2;
    @media (max-width: 767px) {
        height: auto;
    }
    &__bg {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        z-index: -1;
        @media (max-width: 767px) {
            display: block;
        }
    }
    img {
        width: 100%;
    }
    &__right{
        &-animation {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 100vh;
            z-index: 1;
        }
        &-wrapper {
            height: 100%;
            box-shadow: 0px -26px 120px rgb(0 0 0 / 11%);;
        }
    }
    &__main {
        padding: 0 48px;
        height: 100%;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  
        &::-webkit-scrollbar {
            display: none;
        }
        @media (max-width: 767px) {
            padding: 0 24px;
        }
    }
    &__wrapper {
        width: 60%;
        padding-bottom: 100px;
        margin-left: 16%;
        @media (max-width: 1600px) {
            margin-left: unset;
            width: 75%;
        }
        @media (max-width: 767px) {
            width: 100%;
            padding-bottom: 48px;
        }
        
    }
    &__header {
        display: flex;
        align-items: center;
        padding: 56px 0;
        @media (max-width: 991px) {
            .header__line {
                display: block;
            }
        }

        svg {
            width: 16px;
            height: 16px;
            & path {
                stroke: $main-color;
            }
        }
        &-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: $main-color;
        }
        &-line {
            padding: 0 16px;
            &-small {
                max-width: 56px;
            }
        }
    }
    &__title {
        font-family: 'Benzin';
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 125%;
        color: $secondary-color;
        @media (max-width: 1440px) {
            font-size: 42px;
            line-height: 48px;
        }
        @media (max-width: 1199px) {
            font-size: 38px;
            line-height: 42px;
        }
        @media (max-width: 991px) {
            font-size: 34px;
            line-height: 40px;
        }
        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 36px;
            width: min-content;
        }
        @media (max-width: 567px) {
            font-size: 24px;
            line-height: 125%;
        }
        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-link {
            font-family: 'Benzin';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 125%;
            text-decoration-line: underline;
            color: #84AC6C;
        }
    }
    &__technology {
        margin-top: 31px;
        padding: 24px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid  rgba(51, 51, 51, 0.15);
        border-bottom: 1px solid rgba(51, 51, 51, 0.15);
        @media (max-width: 767px) {
            margin-top: 12px;
            padding: 18px 0;
        }
        &-block {
            margin-left: 8px;
            padding: 10px 16px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 102.5%;
            text-transform: uppercase;
            color: $main-color;
            border: 1px solid $main-color;
            border-radius: 50px;
            white-space: nowrap;
            @media (max-width: 767px) {
                padding: 8px 12px;
                font-size: 12px;
            }
            &:first-child {
                margin-left: unset;
            }
        }
        &-stack, &-year {
            display: flex;
        }
        &-year {
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
    &__img {
        &-main {
            margin-top: 90px;
            margin-bottom: 48px;
            width: 100%;
            @media (max-width: 767px) {
                margin-top: 48px;
                margin-bottom: 24px;
            }
        }
        &-border {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 40px;
        color: $main-color;
        a {
            cursor: pointer;
            color: $main-color;
            font-weight: 700;
            text-decoration: underline;
        }
        b {
            font-weight: 700;
            // color: #fff;
        }
        span {
            font-weight: 700;
        }
            @media (max-width: 991px) {
                font-size: 16px;
                line-height: 36px;
            }
            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 32px;
            }
    }
    &__mail {
        text-decoration: none!important;
    }
    &__back {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    &__list {
        margin-top: 16px;
        padding-left: 28px;
        li {
            position: relative;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 40px;
            color: #CECECE;
            animation: animateLi .1s ease;
            @media (max-width: 991px) {
                font-size: 16px;
                line-height: 36px;
            }
            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 32px;
            }
            &::before {
                content: '';
                position: absolute;
                top: 17px;
                left: -17px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #CECECE;
                @media (max-width: 991px) {
                    top: 14px;
                }
                @media (max-width: 767px) {
                    top: 12px;
                }
            }
        }
    }
    &__subtitle {
        font-family: 'Benzin';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        color: $secondary-color;
        &-contact {
            font-family: 'Benzin';
        }
        @media (max-width: 767px) {
            font-size: 20px;
        }
    }
    &__connect {
        b {
            font-weight: 700;
            color: $main-color;
        }
    }
    &__line {
        margin: 100px 0;
        height: 1px;
        background-color: rgba(51, 51, 51, 0.15);
        @media (max-width: 1199px) {
            margin: 75px 0;
        }
        @media (max-width: 767px) {
            margin: 48px 0;
        }
    }
    &__button {
        margin-top: 56px;
        display: inline-block;
        @media (max-width: 767px) {
            margin-top: 16px;
        }
        &-contacts {
            margin-top: 48px;
        }
    }
    .cases__slides-arrows img  {
        width: 34px;
        // height: 48px;
        @media (max-width: 767px) {
            width: 18px;
            // height: 32px;
        }
    }
    &-video {
        display: grid;
        grid-template-columns: 5fr 4fr;
        grid-gap: 70px;
        @media (max-width: 991px) {
            grid-template-columns: 1fr;
        }
        .case__subtitle{
            margin: 0;
        }
        &__img {
            height: 304px;
            @media (max-width: 767px) {
                height: 183px;
            }
        }
        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__text {
            margin-top: 24px;
        }
    }

    & .ezra ,& .fintegrity {
        .case__text {
            b {
                font-weight: 700;
                // color: #fff;
            }

        }

        &__list {
            ul {
                list-style-type: disc;
                padding-left: 24px;
            }
            li {
                position: relative;
            }
        }
        &__line {
            &.case__line {
                margin: 46px 0;
                @media (max-width: 767px) {
                    margin: 32px 0;
                }
            }
        }
        &__img {
            margin: 80px 0;
            @media (max-width: 767px) {
                margin: 48px 0;
            }
        }
    }
    & .broker {
        ul {
            list-style: disc;
            padding-left: 24px;
        }
        &__block:not(:last-child) {
            margin-bottom: 100px;
            @media (max-width: 767px ) {
                margin-bottom: 48px;
            }
        }
    }
    &__slider {
        .swiper-slide {
            width: 137px!important;
            img {
                border-radius: 4px;
            }
        }
    }
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-32 {
    margin-bottom: 32px;
    @media (max-width: 767px) {
        margin-bottom: 24px;
    }
}

.mt-24 {
    margin-top: 24px;
}

.mt-48 {
    margin-top: 48px;
    @media (max-width: 767px) {
        margin-top: 32px;
    }
}

.youtube {
    &-frame {
        position: relative;
        height: 100%;
        width: 100%;
        cursor: pointer;
        border-radius: 8px;
        overflow: hidden;
    }
    &-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: rgba(0, 0, 0, 0.65);
        width: 100px;
        height: 100px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        &__content {
            display: flex;
        }
    }
}
.youtube-preview {
    object-fit: cover;
}