.contacts {
    padding-top: $header-desktop-height;
    height: var(--app-height);
    position: relative;
    z-index: 2;
    &__index {
        z-index: 1000;
    }
    @media (max-width: 1199px) {
        height: auto;
    }
    @media (max-width: 767px) {
        padding-top: $header-mobile-height;
        height: auto;
    }
    @media (max-height: 910px ) {
        height: auto;
    }
    & + .header {
        background: $header-background;
    }
    &__wrapper {
        height: 100%;
        padding: 54px 48px 48px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 1600px) {
            padding: 54px 48px 54px;
        }
        @media (max-width: 1199px) {
            padding: 30px 48px 30px;
        }
        @media (max-width: 767px) {
            padding: 24px 48px 32px;
        }
        @media (max-width: 576px) {
            padding: 24px 24px 32px;
        }
    }
    &__line {
        // margin-top: 54px;
    }
    &__block {
        margin-top: 40px;
    }
    &__title {
        font-family: 'Benzin';
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 56px;
        color: $secondary-color;
        margin-bottom: 40px;
        @media (max-width: 1440px) {
            font-size: 39px;
            line-height: 48px;
        }
        @media (max-width: 1199px) {
            font-size: 36px;
            line-height: 42px;
            margin-bottom: 30px;

        }
        @media (max-width: 991px) {
            font-size: 32px;
            line-height: 40px;
        }
        @media (max-width: 767px) {
            font-size: 25px;
            line-height: 34px;
            margin-bottom: 28px;

        }
    }
    &__form {
        position: relative;
        max-width: 750px;
        width: 100%;
        // transition: all .3s ease;
    }
    &__overlay {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 0);
        @media (max-width: 767px) {
            background: rgba(0, 0, 0, 0.9);
            height: 100vh;
        }
    }
    &__message {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 11px;
        // background: rgba(0, 0, 0, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        & .preloader {
            width: unset;
            height: unset;
            position: relative;
        }
        &-block {
            max-width: 267px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 77px;
                height: 60px;
            }
            p {
                padding-bottom: 8px;
                font-family: Inter;
                font-size: 20px;
                font-weight: 700;
                line-height: 40px;
                border-bottom: 1px solid rgba(64, 64, 64, 1)
            }
            span {
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 32px;
            }
        }
    }
    &__load-container {
        width: 100%;
        height: 49px;
        text-align: center;
    }
    
    &__load-speeding-wheel {
        width: 49px;
        height: 49px;
        margin: 0 auto;
        border: 3px solid rgb(255, 255, 255);
        border-radius: 50%;
        border-left-color: transparent;
        border-right-color: transparent;
        animation: cssload-spin 575ms infinite linear;
            -o-animation: cssload-spin 575ms infinite linear;
            -ms-animation: cssload-spin 575ms infinite linear;
            -webkit-animation: cssload-spin 575ms infinite linear;
            -moz-animation: cssload-spin 575ms infinite linear;
    }
    &__block {
        @media (max-width: 1199px) {
            margin-top: 30px;
        }
        @media (max-width: 767px) {
            margin-top: 48px;
        }
        &-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @media (max-width: 1199px) {
                flex-direction: column;
                align-items: unset;
            }
            @media (max-width: 767px) {

            }
        }
    }
    &__right {
        // max-width: 444px;
        // width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @media (max-width: 1199px) {
            margin-top: 30px;
            // align-self: flex-end;
            align-items: flex-start;
        }
        @media (max-width: 576px) {
            align-self: unset;
            align-items: unset;
            margin-top: 80px;
        }
        &-line {
            margin-top: 40px;
            height: 1px;
            width: 100%;
            background: rgba(51, 51, 51, 0.15);
            @media (max-width: 767px) {
                margin-top: 24px;
            }
        }
    }
    &__flag {
        width: 24px;
        height: 24px;
    }
    &__adress {
        margin-top: 24px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #111;
        @media (max-width: 1199px) {
            text-align: left;
        }
        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 17px;
        }
        @media (max-width: 576px) {
            text-align: start;
        }
    }
    &__cookies {
        margin-top: 16px;
        color: #E31A1A;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 285.714% */
        &-wrapper {}
        svg {
            margin-right: 8px;
            width: 18px;
            height: 18px;
            transform: translateY(20%);
        }
        a {
            color: #E31A1A;
            font-weight: 700;
            text-decoration: underline;
        }
    }
    &__phone, &__mail {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 16px;
        text-align: right;
        color: #111;
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 19px;
        }
        @media (max-width: 576px) {
            text-align: start;
        }
    }
    &__phone {
        margin-top: 40px;
        @media (max-width: 767px) {
            margin-top: 24px;
        }
    }
    &__mail {
        margin-top: 24px;
        @media (max-width: 767px) {
            margin-top: 16px;
        }
    }
    &__form {
        &-inputs {
            display: flex;

            @media (max-width: 767px) {
                flex-direction: column;
            }
            &-second {
                margin-top: 48px;
                @media (max-width: 767px) {
                    margin-top: 16px;
                }
            }
            &-bottom {
                margin-top: 8px;
                input {
                    width: 100%;
                    max-width: unset;
                }
            }
        }
        &-input {
            position: relative;
            // max-width: 250px;
            width: 100%;
            margin-right: 57px;
            &:last-child {
                margin-right: unset;
            }
            &-bottom {
                width: 100%;
                max-width: unset;
                margin-right: unset;

            }
            &-wrapper {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    background: #7C7C7C;
                }
            }
            &-message {
                position: absolute;
                display: flex;
                align-items: center;
                top: 100%;
                left: 0;
                color: #E31A1A;
                font-size: 14px;
                font-family: Inter;
                line-height: 40px;
                @media (max-width: 767px) {
                    position: relative;
                }
                img {
                    margin-right: 8px;
                    pointer-events: none;
                }
            }
            // & .contacts__form-input-error {
            //     color: #E31A1A;
            //     border-bottom: 1px solid #E31A1A;
            //     &::placeholder {
            //         color: #E31A1A;
            //     }
            // }
            @media (max-width: 767px) {
                margin-right: unset;
                max-width: unset;
                margin-bottom: 16px;
                display: flex;
                flex-direction: column-reverse;
                &:last-child {
                    margin-right: unset;
                    @media (max-width: 767px) {
                        margin-bottom: unset;
                    }
                }
            }
            input {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 100%;
                padding: 0;
                padding-bottom: 16px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 200%;
                color: $main-color;
                background-color: transparent!important;
                border: none;
                // border-bottom: 1px solid #7C7C7C;
                outline: none;
                &:-webkit-autofill{
                    transition: all 5000s ease-in-out 0s;
                }
                &::placeholder {
                    color: #7C7C7C;
                }
                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 40px;
                    padding-bottom: 8px;
                }
            }
        }
        &-directions {
            margin-top: 40px;
            font-family: 'Benzin';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 56px;
            color: $secondary-color;
            @media (max-width: 767px) {
                margin-top: 28px;
                font-size: 17px;
                line-height: 42px;
            }
        }
        &-checkboxs {
            margin-top: 22px;
            display: flex;
            @media (max-width: 767px) {
                margin-top: 12px;
                flex-wrap: wrap;
            }
        }
        &-checkbox {
            margin-right: 56px;
            @media (max-width: 767px) {
                margin-right: 32px;
                margin-bottom: 16px;
            }
            &:last-child {
                margin-right: unset;
            }
            input {
                position: absolute;
                opacity: 0;
                &:checked+label {
                    color: $secondary-color;
                    &::before {
                        background: $secondary-color;
                        // border: 1px solid #B0B0B0;
                    }
                    &::after {
                        opacity: 1;
                    }
                }
            }
            label {
                cursor: pointer;
                position: relative;
                padding-left: 28px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: $main-color;
                transition: all .2s ease;
                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 17px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                    height: 16px;
                    // background: #131313;
                    border: 1px solid #333333;
                }
                &::after {
                    opacity: 0;
                    content: '';
                    position: absolute;
                    background: url(../../assets/img/icons/arrow-done.svg) center center/cover no-repeat;
                    width: 10px;
                    height: 10px;
                    left: 3px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        &-button {
            margin-top: 46px;
            @media (max-width: 767px) {
                margin-top: 32px;
            }
            @media (max-width:576px) {
                width: 100%;
            }
        }
    }
}

@keyframes cssload-spin {
	100%{ transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes cssload-spin {
	100%{ -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@-ms-keyframes cssload-spin {
	100%{ -ms-transform: rotate(360deg); transform: rotate(360deg); }
}

@-webkit-keyframes cssload-spin {
	100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes cssload-spin {
	100%{ -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}