.directions {
    padding-top: $header-desktop-height;
    height: var(--app-height);
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    @media (max-width: 767px) {
        padding-top: $header-mobile-height;
    }
    &__top {
        z-index: 1;
        padding: 54px 48px 0;
        @media (max-width: 991px) {
            padding: 32px 48px 0;
        }
        @media (max-width: 576px) {
            padding: 24px 24px 0;
        }
        @media (max-height: 900px) and (min-width: 577px) {
            padding: 12px 48px 0;
        }
    }
    &__line {
        // margin-top: 54px;
    }
    &__videos {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &-overlay {
            pointer-events: none;
            position: absolute;
            top: 0;
            z-index: 1;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(../../assets/img/dot_bg.png) left top repeat;
            @media (max-width: 767px) {
                display: none;
            }
          }
    }
    &__wrapper {
        display: flex;
        align-items: center;
        flex: 1;
        z-index: 1;
        // margin-top: 83px;
    }
    &__block {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: flex-start;

        @media (max-width: 1600px) {
            margin-top: 40px;
        }
        @media (max-width: 991px) {
            padding-bottom: 20px;
            align-items: center;
        }
        @media (max-width: 767px) {
            margin-top: 36px;
        }
        @media (max-height: 600px) {
            margin-top: unset;
        }
        &-accordion {
            display: none;
            width: 100%;
            @media (max-width: 991px) {
                display: block;
                height: 100%;
                display: flex;
                flex-direction: column;
            }
            .directions__block-top {
                border-bottom: unset;
                @media (max-width: 991px) {

                }
            }
        }
        &-content {
            display: none;
            max-height: 0;
            overflow: hidden;
            transition: .3s;
            @media (max-width: 991px) {
                display: block;
                padding: 0 48px;
            }
            @media (max-width: 576px) {
                padding: 0 24px;
            }
        }
        &-left {
            width: 47.3%;
            @media (min-width: 1199px) {
                cursor: none;
            }
            @media (max-width: 991px) {
                width: 100%;
                display: none;
            }
        }
        &-right {
            // margin-top: 48px;
            display: flex;
            align-items: center;
            padding-right: 48px;
            width: 45.5%;
            @media (max-width: 1199px) {
                width: 50%;
            }
            @media (max-width: 991px) {
                display: none;
            }
            img {
                width: 100%;
            }
        }
        &-list {
            margin-top: 40px;
            padding-left: 30px;
            @media (max-width: 991px) {
               margin-top: unset;
            }
            li {
                position: relative;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 48px;
                color: $main-color;
                animation: animateLi .1s ease;
                b {
                    font-weight: inherit;
                    white-space: nowrap;
                }
                @media (max-width: 991px) {
                    font-size: 16px;
                }
                @media (max-width: 767px) {
                    font-size: 14px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 20px;
                    left: -19px;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    background-color: $main-color;
                }
            }
        }
        &-item {
            border-bottom: 1px solid rgba(51, 51, 51, 0.15);
            @media (max-width: 991px) {
                flex: 1;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
            @media (max-width: 576px) {
                flex: unset;
                display: unset;
                flex-direction: unset;
            }
            &-active {
                svg {
                    opacity: 0;
                    @media (max-width: 991px) {
                        opacity: 1;
                    }
                }
                .directions__block {
                    &-top {
                        background-color: #FFFFF0;
                    }
                    &-content {
                        @media (max-width: 991px) {
                            padding: 40px 48px;
                        }
                        @media (max-width: 576px) {
                            padding: 40px 24px;
                        }
                    }
                }
            }
        }
        &-top {
            cursor: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 48px 25px  40px 48px;
            transition: all .4s ease;
            @media (max-width: 1440px) {
                padding: 35px 25px 35px 48px;
            }
            @media (max-width: 991px) {
                
            }
            @media (max-width: 576px) {
                padding: 32px 24px;
            }
            @media (max-height: 900px) and (min-width: 768px){
                padding: 16px 25px 16px 48px;
            }
            &-title {
                font-family: 'Benzin';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 63px;
                color: $secondary-color;
                @media (max-width: 1440px) {
                    font-size: 38px;
                    line-height: 50px;
                }
                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 39px;
                }
                @media (max-height: 900px) and (min-width: 768px) {
                    font-size: 36px;
                    line-height: 45px;
                }
            }
            svg {
                // cursor: pointer;
                width: 56px;
                height: 56px;
                & path {
                    fill: $main-color;
                }
                @media (max-width: 767px) {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
    &__cursor {
        position: fixed;
        will-change: transform;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        transform: translate(calc(-50%),-50%);
        transition: opacity .3s;
        opacity: 0;
        background: rgba(0, 0, 0, 0.1);
        width: 100px;
        height: 100px;
        border-radius: 100%;
        @media (max-width: 1199px) {
            display: none;
        }
        svg {
            width: 40px;
            height: 40px;
            & path {
                fill: $main-color;
            }
        }
        &.hover {
            opacity: 1;
        }
    }
    &__it {
        max-width: 531px;
        width: 100%;
        background: #FFFFF0;
        display: grid;
        grid-template-columns: 33px 1fr;
        // border: 1px solid #000000;
        border-radius: 7px;
        &-lines {
            padding-left: 11px;
            width: 100%;
            div {
                font-family: 'Source Code Pro';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 32px;
                color: $main-color;
                @media (max-width: 1199px) {
                    font-size: 15px;
                }
            }
            b {
                color: #464646;
            }
            i {
                color: #5B9DD5;
                font-style: normal;
            }
        }
        &-num {
            background-color: #FFFFF0;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 32px;
            text-align: center;
            color: #464646;
            &:first-child {
                border-top-left-radius: 7px;
            }
            &:last-child {
                border-bottom-left-radius: 7px;
            }
        }

        &-ul {
            padding-left: 40px;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;
                width: 1px;
                background-color: $main-color;
            }
        }
    }
    &__marketing {
        max-width: 501px;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center;
        font-family: "Microsoft Sans Serif", sans-serif;
        background: #c2c6ca;
        font-size: 12px;
        -webkit-font-smoothing: none;
        box-shadow: 0.5px 0.5px 0 0.5px black, inset 1px 1px #C2C6CA, inset -1px -1px #85898d, inset 2px 2px white;
        &-wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            img {
                flex: 1 1 50%;
                // max-width: 250px;
                width: 100%;
                height: 100%;
            }
        }
        &-list {
            flex: 1 1 50%;
        }
        &-bar {
            display: flex;
            flex-flow: row nowrap;
            font-weight: bold;
            justify-content: space-between;
            width: calc(100% - 4px);
            color: white;
            background: #00a;
            padding: 2px 3px 3px;
            margin: 3px 2px 1px 3px;
        }
        &-button {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #c2c6ca;
            border: none;
            padding: 0;
            border-radius: 0;
            outline: none;
            width: 15px;
            height: 13px;
            &:active {
                box-shadow: 0.5px 0.5px 0 0.5px white, inset 1px 1px black,
                  inset -1px -1px #c2c6ca, inset 2px 2px #85898d;
            svg {
                transform: translateX(1px) translateY(1px);
            }
            }
            svg {
                // margin: 3px 3px 3px 4px;
            }
        }
        &-shadow {
            box-shadow: 0.5px 0.5px 0 0.5px black, inset 1px 1px white,
            inset -1px -1px #85898d;
        }
        &-title {
            font-family: 'Tahoma';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #FFFFFF;
        }
        li {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            text-shadow: 
            -1px 0 0 rgb(0, 0, 0), /* Тень влево */
            0 -1px 0 rgb(0, 0, 0), /* Тень вверх */
            1px 0 0 rgb(0, 0, 0), /* Тень вправо */
            0 1px 0 rgb(0, 0, 0), /* Тень вниз */
             2px 2px 0 rgb(0, 0, 0); /* Тень вниз */
            span {
                font-family: 'Tahoma', sans-serif ;
            }
        }
        &-seo {
            font-family: 'Algerian', 'Brush Script MT';
            animation: rainbow 2.5s linear;
            animation-iteration-count: infinite;
        }
        &-smm {
            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
            animation: rainbow 2.5s linear;
            animation-iteration-count: infinite;
            animation-delay: -2s;
        }
        &-design {

            font-family: 'Courier New', Courier, monospace;
            animation: rainbow 2.5s linear;
            animation-iteration-count: infinite;
            animation-delay: -1.5s;
            font-weight: 700!important;
        }
        &-copy {
            font-family: Tahoma;
            // font-family: 'Courier New', Courier, monospace;
            animation: rainbow 2.5s linear;
            animation-iteration-count: infinite;
            animation-delay: -1s;


        }
        &-community {
            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
            animation: rainbow 2.5s linear;
            animation-iteration-count: infinite;
        }
        &-analitic {
            font-family: 'Comic Sans MS';
            animation: rainbow 2.5s linear;
            animation-iteration-count: infinite;
            animation-delay: -1.8s;
        }
    }
    &__fintech {
        max-width: 560px;
        width: 100%;
        &-title {
            padding-left: 5px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            text-transform: uppercase;
            color: $main-color;
        }
        &-wrapper {
            margin-top: 15px;
            padding: 16px 26px;
            background: #FFFFF0;
            border: 1px solid rgba(255, 255, 255, 0.07);
            border-radius: 7px;
        }
        ul {
            
        }
        li {
            position: relative;
            padding-left: 14px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 40px;
            color: $main-color;
            &::before {
                content: '';
                position: absolute;
                top: 16px;
                left: 0;
                width: 7px;
                height: 7px;
                border-radius: 100%;
                background-color: #FFFFFF;
            }
        }
    }
    &__web {
        padding: 13px;
        background: #FFFFF0;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 7px;
        &-line {
            display: grid;
            grid-template-columns: repeat(36, 1fr);
            gap: 4px;
            margin-bottom: 4px;
            &:last-child {
                margin-bottom: unset;
            }
        }
        &-item {
            overflow: hidden;
            position: relative;
            // width: 20px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            background: #FFF8E7;
            font-family: Helvetica Neue, Arial;
            font-size: 18px;
            color: $main-color;
            font-weight: 700;
            text-transform: uppercase;
            @media (max-width: 1440px) {
                font-size: 14px;
            }
            @media (max-width: 1199px) {
                font-size: 13px;
            }
            &:after{
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: calc(50% - 1px);
                // border-bottom: 1px solid #000;
                background:  rgba(0,0,0,0.1);
                box-shadow: 0 1px 0 rgba(255,255,255,0.1);
            }
            &:before{
                content: '';
                position: absolute;
                top: 50%;
                left: 0px;
                width: 100%;
                height: 50%;
                background:  rgba(0,0,0,0.1);
                z-index: 2;
            }
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
                position: absolute;
                top: 0px;
                transition: transform 2s;
                transform: translateY(0);
                height: 100%;
            }
            li{
                height: 63px;
                width: 16px;
                text-align: center;
                padding-top: 7px;
            }
        }
    }
}

@keyframes rainbow {
    100%, 0% {
      color: rgb(255, 0, 0);
    }
    8% {
      color: rgb(255, 127, 0);
    }
    33% {
      color: rgb(0, 255, 0);
    }
    41% {
      color: rgb(0, 255, 127);
    }
    58% {
      color: rgb(0, 127, 255);
    }
    66% {
      color: rgb(0, 0, 255);
    }
    75% {
      color: rgb(127, 0, 255);
    }
    91% {
      color: rgb(255, 0, 127);
    }
  }
  

// @keyframes rainbow1{
//     100%,0%{
//         color: rgb(255,0,0);
//     }
//     8%{
//         color: rgb(255,127,0);
//     }
//     16%{
//         color: rgb(255,255,0);
//     }
//     25%{
//         color: rgb(127,255,0);
//     }
//     33%{
//         color: rgb(0,255,0);
//     }
//     41%{
//         color: rgb(0,255,127);
//     }
//     50%{
//         color: rgb(0,255,255);
//     }
//     58%{
//         color: rgb(0,127,255);
//     }
//     66%{
//         color: rgb(0,0,255);
//     }
//     75%{
//         color: rgb(127,0,255);
//     }
//     83%{
//         color: rgb(255,0,255);
//     }
//     91%{
//         color: rgb(255,0,127);
//     }
// }

// @keyframes rainbow2 {
//     0% {
//       color: rgb(0, 255, 127); /* Змінений початковий колір */
//     }
//     8% {
//       color: rgb(0, 255, 0);
//     }
//     16% {
//       color: rgb(0, 127, 255);
//     }
//     25% {
//       color: rgb(255, 0, 127);
//     }
//     33% {
//       color: rgb(255, 0, 0);
//     }
//     41% {
//       color: rgb(127, 255, 0);
//     }
//     50% {
//       color: rgb(0, 255, 255);
//     }
//     58% {
//       color: rgb(255, 0, 255);
//     }
//     66% {
//       color: rgb(0, 0, 255);
//     }
//     75% {
//       color: rgb(127, 0, 255);
//     }
//     83% {
//       color: rgb(255, 255, 0);
//     }
//     91% {
//       color: rgb(255, 127, 0);
//     }
//     100% {
//       color: rgb(0, 255, 127); /* Змінений кінцевий колір */
//     }
//   }
  
//   @keyframes rainbow3 {
//     0% {
//       color: rgb(0, 255, 255);
//     }
//     8% {
//       color: rgb(0, 0, 255);
//     }
//     16% {
//       color: rgb(255, 0, 0);
//     }
//     25% {
//       color: rgb(255, 127, 0);
//     }
//     33% {
//       color: rgb(0, 255, 0);
//     }
//     41% {
//       color: rgb(0, 255, 127);
//     }
//     50% {
//       color: rgb(0, 127, 255);
//     }
//     58% {
//       color: rgb(255, 0, 127);
//     }
//     66% {
//       color: rgb(127, 255, 0);
//     }
//     75% {
//       color: rgb(0, 255, 255);
//     }
//     83% {
//       color: rgb(255, 0, 255);
//     }
//     91% {
//       color: rgb(255, 255, 0);
//     }
//     100% {
//       color: rgb(0, 255, 255);
//     }
//   }
  
//   @keyframes rainbow4 {
//     0% {
//       color: rgb(255, 0, 255);
//     }
//     8% {
//       color: rgb(255, 0, 0);
//     }
//     16% {
//       color: rgb(0, 255, 255);
//     }
//     25% {
//       color: rgb(0, 255, 127);
//     }
//     33% {
//       color: rgb(0, 127, 255);
//     }
//     41% {
//       color: rgb(255, 0, 127);
//     }
//     50% {
//       color: rgb(127, 255, 0);
//     }
//     58% {
//       color: rgb(0, 0, 255);
//     }
//     66% {
//       color: rgb(0, 255, 0);
//     }
//     75% {
//       color: rgb(255, 127, 0);
//     }
//     83% {
//       color: rgb(0, 255, 0);
//     }
//     91% {
//       color: rgb(255, 255, 0);
//     }
//     100% {
//       color: rgb(255, 0, 255);
//     }
//   }

//   @keyframes rainbow5 {
//     0% {
//       color: rgb(0, 255, 0);
//     }
//     8% {
//       color: rgb(255, 0, 0);
//     }
//     16% {
//       color: rgb(0, 255, 255);
//     }
//     25% {
//       color: rgb(255, 127, 0);
//     }
//     33% {
//       color: rgb(0, 127, 255);
//     }
//     41% {
//       color: rgb(255, 0, 127);
//     }
//     50% {
//       color: rgb(127, 255, 0);
//     }
//     58% {
//       color: rgb(0, 0, 255);
//     }
//     66% {
//       color: rgb(0, 255, 0);
//     }
//     75% {
//       color: rgb(255, 255, 0);
//     }
//     83% {
//       color: rgb(0, 255, 0);
//     }
//     91% {
//       color: rgb(255, 0, 255);
//     }
//     100% {
//       color: rgb(0, 255, 0);
//     }
//   }

//   @keyframes rainbow6 {
//     0% {
//       color: rgb(0, 255, 255);
//     }
//     8% {
//       color: rgb(0, 0, 255);
//     }
//     16% {
//       color: rgb(255, 255, 0);
//     }
//     25% {
//       color: rgb(255, 0, 127);
//     }
//     33% {
//       color: rgb(0, 255, 0);
//     }
//     41% {
//       color: rgb(127, 255, 0);
//     }
//     50% {
//       color: rgb(0, 127, 255);
//     }
//     58% {
//       color: rgb(255, 0, 0);
//     }
//     66% {
//       color: rgb(0, 255, 0);
//     }
//     75% {
//       color: rgb(255, 127, 0);
//     }
//     83% {
//       color: rgb(0, 255, 127);
//     }
//     91% {
//       color: rgb(255, 0, 255);
//     }
//     100% {
//       color: rgb(0, 255, 255);
//     }
//   }