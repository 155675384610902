.button {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    border: 1px solid #333;;
    border-radius: 71px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    white-space: nowrap;
    transition: all .3s ease;
    &-white {

        &:hover  {
            background-color: #333;
        }
    }
    &:disabled {
        cursor: not-allowed;
        opacity: .8;
        &.button--bestia .button__bg {
            background: #2B2B2B;
        }
    }
    svg {
        margin-left: 6px;
        width: 20px;
        height: 20px;
        z-index: 1;
    }
    svg path  {
        fill: #000;
    }
    &-black {
        background-color: #111;
    }
    &-case {
        background-color: #fff;
        border: none;
        &:hover {
            background-color: $main-color;
        }
    }
}

