.terms {
    padding:  calc($header-desktop-height + 72px ) 48px 68px;
    position: relative;
    z-index: 2;
    & + .header {
        background: #fffff0;
    }
    @media (max-width: 767px) {
        padding: $header-mobile-height + 28px 24px 46px;
    }
    &__wrapper {
        max-width: 1210px;
        width: 100%;
        margin: 0 auto;
    }
    &__title {
        color: $main-color;
        font-size: 48px;
        font-family: Benzin;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
        @media (max-width: 1440px) {
            font-size: 42px;
            line-height: 48px;
        }
        @media (max-width: 1199px) {
            font-size: 38px;
            line-height: 42px;
        }
        @media (max-width: 991px) {
            font-size: 34px;
            line-height: 40px;
        }
        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 36px;
        }
        @media (max-width: 567px) {
            // margin-top: 35px;
            font-size: 24px;
            line-height: 125%;
        }
    }
    &__update {
        margin-top: 32px;
        p {
            color: $main-color;
            font-size: 18px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
        &-inner {
            display: flex;
            padding: 24px 0;
            border-top: 1px solid $line-color;
            border-bottom: 1px solid $line-color;
            &:not(:last-child) {
                margin-bottom: 32px;
            }
            @media (max-width: 767px) {
                padding: 18px 0;
            }
        }
        &-date {
            padding: 10px 16px;
            color: $main-color;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            line-height: 102.5%;
            text-transform: uppercase;
            border-radius: 50px;
            border: 1px solid $main-color;
            @media (max-width: 767px) {
                font-size: 12px;
            }
        }
    }
    &__nav {
        position: relative;
        margin: 46px 0;
        @media (max-width: 767px) {
            margin: 28px 0;
        }
        &-fixed {
            position: fixed;
            margin-top: 0;
            top: $header-desktop-height;
            left: 0;
            width: 100%;
            background: #fffff0;
            z-index: 1;
            @media (max-width: 767px) {
                top: $header-mobile-height;
            }
            & .container {
                max-width: 1306px;
                padding: 0 48px;
                width: 100%;
                margin: 0 auto;
                @media (max-width: 767px) {
                    padding:0 24px;
                }
            }
        }
        &-inner {
            position: relative;
        }
        &-items {
            position: relative;
            display: flex;
            overflow: hidden;
        }
        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(50%);
            background: $secondary-color;
            border-radius: 4px;
            width: 36px;
            height: 36px;
            cursor: pointer;
            font-size: 16px;
            z-index: 1;
            transition: all .3 ease;
            @media (max-width: 767px) {
                top: 7px;
                width: 26px;
                height: 26px;
            }
            svg {
                width: 100%;
                height: 100%;
                & path {
                    fill: #fff;
                }
            }
            &-active {
                opacity: 1;
                visibility: visible;
            }
            &-left {
                left: 0 ;
                transform: rotate(180deg) translateX(50%);
            }
        }
        &-item {
            cursor: pointer;
            position: relative;
            flex-shrink: 0;
            padding-right: 46px;
            color: $main-color;
            font-size: 16px;
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            div {
                pointer-events: none;
                position: relative;
                padding-bottom: 12px;
            }
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $line-color;
            }
            &:first-child {
                padding-left: 20px;
            }
            &:last-child {
                padding-right: 20px;
            }
            @media (max-width: 767px) {
                font-size: 16px;
            }
            &-active {
                & div::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: $secondary-color;
                }
            }
        }
    }
    &__content {
        &-chapter {
            margin-bottom: 46px;
            &-title {
                position: relative;
                color: $main-color;
                font-size: 24px;
                font-family: Benzin;
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
                padding-bottom: 46px;
                margin-bottom: 46px;
                @media (max-width: 767px) {
                    font-size: 20px;
                    padding-bottom: 28px;
                    margin-bottom: 28px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    background: $line-color;
                    left: 0;
                    bottom: 0;
                }
            }
            &-text {
                color: $main-color;
                font-size: 18px;
                font-family: Inter;
                font-style: normal;
                font-weight: 400;
                line-height: 40px;
                @media (max-width: 767px) {
                    font-size: 14px;
                }
                h4 {
                    color: $main-color;
                    font-size: 24px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    margin-bottom: 12px;
                }
                a {
                    font-weight: 700;
                    color: $main-color;
                }
                strong {
                    font-weight: 700;
                    color: $main-color;
                }
                p {
                    &:not(:last-child) {
                        margin-bottom: 46px;
                        @media (max-width: 767px) {
                            margin-bottom: 28px;;
                        }
                    }
                }
            }
            ul {
                list-style-type: disc;
                padding-left: 28px;
                &:not(:last-child) {
                    margin-bottom: 46px;
                }
            }
        }
    }
}