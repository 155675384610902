

.cookies {

  &-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    background: #000000;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;  
    color: #fff;
    .container {
      padding: 0 48px;
      @media (max-width: 576px) {
        padding: 0 24px;
      }
    }
    &-link {
      cursor: pointer;
      display: inline;
      color: #fff;
      text-decoration: underline;
      &:hover {
        // color: #323232;
      }
    }
    &__inner {
      display: flex;
      padding: 8px 0;
      align-items: center;
      justify-content: center;
      height: $cookies-desktop-height;
    }
    &__buttons {
      display: flex;
      align-items: center;
      font-family: Inter;
      font-size: 12px;
      line-height: 12px;

    }
    &__button {
      cursor: pointer;
      &_accept {
        position: relative;
        border: 1px solid #fff;
        padding: 10px 20px ;
        border-radius: 71px;
        background-color: #fff;
        font-weight: 800;
        color: #000;

      }
      &_decline {
        font-size: 12px;
        text-transform: unset;
        font-family: inherit;
        &::before {
          color: #fffff0;
        }
      }
      &_settings {
        width: 20px;
        height: 20px;
        img {
          width: 100%;
        }
      }
    }

    & ~ .header {
      top: $cookies-desktop-height;
      // @media (max-width: 767px) {
      //   & .header__nav_active {
      //     top: $header-mobile-height + $cookies-mobile-height;
      //     height: calc(100vh - #{$header-mobile-height + $cookies-mobile-height})
      //   }
      // }

    }
    & ~ section.terms {
      & .terms__nav {
        &-fixed {
          top: $header-desktop-height + $cookies-desktop-height;
          @media (max-width: 767px) {
            top: $header-mobile-height + $cookies-mobile-height;
          }
        }
      }
    }

    & ~ section.cases {
      @media (max-width: 767px) and (max-height: 900px) {
        height: auto;
        & .cases__wrapper {
          height: auto;
        }
      }
    }

    & ~ section.directions {
      @media (max-width: 767px) and (max-height: 900px) {
        height: auto;
      }
    }

    & ~ .case {
      & .case__right-wrapper {
        position: relative;
        top: $cookies-desktop-height;
        @media (max-width : 767px) {
          top: $cookies-mobile-height;
        }
      }
      & .cases {
        height: calc(var(--app-height) - $cookies-desktop-height);

        @media (max-width: 767px) {
          height: calc(var(--app-height) - $cookies-mobile-height - 24px)
        }
      }
      & .case__back {
        top: $cookies-desktop-height;
        @media (max-width : 767px) {
          top: $cookies-mobile-height;
        }
      }
      & .case__main {
        padding-top: $cookies-desktop-height;
        @media (max-width : 767px) {
          padding-top: $cookies-mobile-height;
        }
      }
    }

    & ~ section {
      padding-top: $header-desktop-height + $cookies-desktop-height;
      @media (max-width: 767px) {
        padding-top: $header-mobile-height + $cookies-mobile-height;
      }
    }

    & ~ section.terms {
      padding-top: calc(178px + $cookies-desktop-height) ;
    }

    & ~ .aboutUs {
      padding-top: $cookies-desktop-height;
      @media (max-width: 767px) {
        padding-top: $cookies-mobile-height;
      }
      .aboutUs__top-img {
        top: $cookies-desktop-height;
      }
    }

    // & ~ section.about {
    //   padding-top: $header-desktop-height + $cookies-desktop-height;
    //   @media (max-width: 767px) {
    //     padding-top: $header-mobile-height + $cookies-mobile-height;
    //   }
    // }
   
    @media (max-width: 767px) and (max-height: 720px ) {
      & ~ .header {
        & .header__nav {
          &-mobile {
            padding-top: 100px;
          }
        }
      }
    }
    @media (max-width: 1199px) {
      &__text {
        line-height: 16px;
      }
    }
    @media (min-width: 768px) {
      &__text {
        padding-right: 30px;
      }
    }
    @media (max-width: 767px) {
      &__inner {
        padding: 16px 0;
        flex-direction: column;
        height: $cookies-mobile-height;
      }
      &__text {
        padding-bottom: 16px;
        font-size: 12px;
        line-height: 18px;
      }

      & ~ .header {
        top: $cookies-mobile-height;
        & .header__nav {
          &-mobile {
            overflow: scroll;
            top:  $cookies-mobile-height;
            height: calc(100vh - $cookies-mobile-height);
          }
        }
      }

    }

    &__button {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }

    @media (max-width: 576px) {
      &__buttons {
        width: 100%;
      }
    }
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 50%;
    z-index: 20;
    background: rgba(0, 0, 0, 0.80);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-popup {
    position: relative;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(10px);
    max-width: 565px;
    // max-height: 738px;
    // height: 100vh;
    width: 100%;
    padding: 42px 30px;
    font-size: 13px;
    line-height: 24px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 11px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width : 767px) {
      background: rgba(22, 21, 26, 0.98);
      padding: 28px 24px 32px;
      top: 0;
      left: 0;
      transform: unset;
      height: var(--app-height);
    }
    @media (orientation: landscape) and (max-height: 650px) {
      height: var(--app-height);
    }
    &__title {
      display: flex;
      align-items: center;
      color: #FFF;
      font-size: 16px;
      font-family: Benzin;
      font-style: normal;
      font-weight: 700;
      line-height: 102.5%;
      text-transform: uppercase;
      margin-bottom: 16px;
      img {
        margin-left: 10px;
      }
    }
    &__subtitle {
      padding-bottom: 16px;
      color: #FFF;
      font-size: 12px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      a {
        color: #fff;
        text-decoration: underline;
        font-weight: 700;
      }
    }
    &__buttons {
      border-bottom: 1px solid #353439;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }
    &__text {
      color: #FFF;
      font-size: 12px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &__close {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
      z-index: 30;
    }
    &__button {
      font-family: Inter;
      font-size: 12px;
      line-height: 100%;
      cursor: pointer;
      &.btn_yellow {
        // border-color: $accent-color;
      }
      &:not(:last-child) {
        margin-right: 30px;
      }
      &:last-child {
        color: #fff;
      }
    }
    &__wrap {
      &:not(:last-child) {
        border-bottom: 1px solid #353439;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }
    &__caption {
      color: #FFF;
      font-size: 18px;
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      &-wrap {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}