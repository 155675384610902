.aboutUs {
    position: relative;

    z-index: 2;
    & + .header {
        background: $header-background;

    }
    &__bg {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        z-index: -1;
        @media (max-width: 767px) {
            display: block;
        }
    }
    &__main {
        position: relative;
        padding-bottom: 100px;
        padding-top: 80px;
        @media (max-width: 991px) {
            padding-top: 45px;
            padding-bottom: 48px;
        }
        @media (max-width: 767px) {
            padding-top: unset;
        }


        &-title {
            font-family: 'Benzin';
            font-style: normal;
            font-weight: 500;
            font-size: 48px;
            line-height: 56px;
            color: $secondary-color;
            mix-blend-mode: normal;
            @media (max-width: 1440px) {
                font-size: 42px;
                line-height: 48px;
            }
            @media (max-width: 1199px) {
                font-size: 38px;
                line-height: 42px;
            }
            @media (max-width: 991px) {
                font-size: 34px;
                line-height: 40px;
            }
            @media (max-width: 767px) {
                font-size: 28px;
                line-height: 36px;
            }
            @media (max-width: 567px) {
                font-size: 24px;
                line-height: 125%;
            }
        }
    }
    &__container {
        width: 100%;
        padding: 0 48px;
        &.fluid {
            padding: unset;
        }
        @media (max-width: 767px) {
            padding: 0 24px;
            &.fluid {
                padding: 0;
                .directions__block-top {
                   padding: 30px 24px;
                }
                .aboutUs__title {
                    // padding: 0 24px;
                }
            }
        }
    }
    &__wrapper {
        max-width: 1210px;
        width: 100%;
        @media (max-width: 1750px) {
            max-width: 1020px;
        }
    }

    &__video {
        &-overlay {
            position: absolute;
            top: -2px;
            left: 0;
            width: 100%;
            height: 300px;
            background: linear-gradient(180deg, #fffff0 0%, rgba(255, 255, 240, 0) 100%);
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
    &__subtitle {
        margin-top: 40px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 40px;
        color: $main-color;
        mix-blend-mode: normal;
        @media (max-width: 1440px) {
            font-size: 18px;
            line-height: 32px;
        }
        @media (max-width: 991px) {
            font-size: 16px;
        }
        @media (max-width: 767px) {
            margin-top: 24px;
        }
        @media (max-width: 576px) {
            font-size: 14px;
        }
    }

    &__header {
        // background: url('../../assets/img/about-us-background.png') center center/cover no-repeat;
        display: flex;
        align-items: center;
        padding: 123px 48px 96px;
        @media (max-width: 991px) {
            padding: 103px 48px 50px;
        }
        @media (max-width: 767px) {
            background: unset;
            padding: 103px 24px 78px;
        }
        svg {
            width: 16px;
            height: 16px;
            & path {
                stroke: $main-color;
            }
        }
        &-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: $main-color;
        }
        &-line {
            @media (max-width: 991px) {
                &.header__line {
                    display: block;
                }
            }
            padding: 0 16px;
            &-small {
                max-width: 56px;
            }
            &-big {
                padding-right: 0;
            }
        }
    }

    &__line {
        margin: 100px 0;
        height: 1px;
        background-color: $line-color;
        @media (max-width: 991px) {
            margin: 75px 0;
        }
        @media (max-width: 767px) {
            margin: 48px 0;
        }
    }
    &__title {
        font-family: 'Benzin';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 56px;
        color: $secondary-color;
        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 40px;
        }
    }
    &__history {
        display: flex;
        justify-content: space-between;
        padding-bottom: 100px;
        @media (max-width: 991px) {
            flex-direction: column;
            padding-bottom: 50px;
        }
        &-text {
            margin-top: 32px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 40px;
            color: $main-color;
            span {
                font-weight: 700;
            }
            @media (max-width: 767px) {
                margin-top: 24px;
                font-size: 14px;
                line-height: 32px;
            }
        }
        &-line {
            display: flex;
            padding-bottom: 40px;
            align-items: stretch;
            &-wrapper {
                display: flex;
            }
        }
        &-year {
            width: 175px;
            font-family: 'Benzin';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            color: $main-color;
            @media (max-width: 576px) {
                width: 150px;
            }
        }
        &-middle {
            position: relative;
            margin-right: 64px;
            @media (max-width: 576px) {
                margin-right: 50px;
            }
            &-line , &-topLine, &-bottomLine{
                position: absolute;
                top: 0;
                height: calc(100% + 40px);
                width: 1px;
                background: #414141;
                left: 50%;
                transform: translateX(-50%);
            }
            &-lineLast {
                height: 10px;
                
            }
            &-topLine {
                height: 24px;
                top: -24px;
                border: 1px dashed #414141;
                // width: 1px;
                background-color: unset;
            }
            &-bottomLine {
                top: 20px;
                border-left: 1px dashed #6FC13D;
                height: 36px;
            }
        }
        &-circle {
            position: relative;
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 23px;
            height: 23px;
            border: 1px solid #FFFFFF;
            border-radius: 100%;
            background: #0B0B0B;
            z-index: 1;
            div {
                width: 11px;
                height: 11px;
                border-radius: 100%;
                background: #FFFFFF;
            }
            &-green {
                border: 1px solid #6FC13D;
                div {
                    background: #6FC13D;
                }
            }
        }
        &-descr {
            max-width: 182px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 32px;
            color: $main-color;
            a {
                cursor: pointer;
                text-decoration-line: underline;
                font-weight: 700;
                color: #111;
            }
            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }
        &-left {
            max-width: 598px;
            width: 100%;
            @media (max-width: 1750px) {
                margin-right: 56px;
                max-width: 518px;
            }
            @media (max-width: 991px) {
                margin-right: unset;
            }
        }
        &-right {
            padding-top: 20px;
            @media (max-width: 991px) {
                margin-top: 40px;
                align-self: center;
            }
        }
    }
    &__charity {
        display: grid;
        grid-template-columns: 1fr 1fr;
        &-descr {
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translate(-50%);
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.64);
            backdrop-filter: blur(1px);
            padding: 15px 12px;
            width: 80%;
            text-align: center;
            color: #DFDFDF;
            text-align: center;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 100% */
            @media (max-width: 767px) {
                font-size: 16px;
                line-height: 16px;
            }
        }
        .swiper {
            width: 100%;
            &:hover {
                .aboutUs__charity-arrow {
                    opacity: 1;
                    visibility: visible;
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        @media (max-width: 1199px) {
            grid-template-columns: 1fr;
        }
        @media (max-width: 767px) {
            margin-top: 24px;
        }
        &-arrow {
            opacity: 0;
            display: flex;
            visibility: hidden;
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.25);
            transition: all .3s ease;
            &:hover {
                background: rgba(255, 255, 255, 0.5);
            }
            img {
                width: 100%;
                height: 100%;
            }
            &-left {
                left: 24px;
                padding: 4px;
            }
            &-right {
                right: 24px;
                padding: 4px;
            }
        }
        &-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: #FFFFFF;
            padding: 85px 48px;
            @media (max-width: 767px) {
                padding: 40px 24px;
            }
        }
        &-title {
            color: #000;
            display: flex;
            align-items: center;
            picture {
                margin-left: 16px;
                width: 48px;
                height: 48px;
                img {
                    width: 100%;
                    height: 100%;
                }
                @media (max-width: 767px) {
                    width: 24px;
                    height: 24px;
                }
            }
        }
        &-text {
            margin-top: 32px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 40px;
            color: #3C3C3C;;
            @media (max-width: 767px) {
                margin-top: 24px;
                font-size: 14px;
                line-height: 32px;
            }
        }
    }
    &__contact {
        &-button {
            margin-top: 48px;
            @media (max-width: 767px) {
                margin-top: 24px;
            }
        }
        &-subtitle {
            margin-top: 24px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 40px;
            color: $main-color;
            a {
                &:first-child {
                    cursor: pointer;
                    color: $main-color;
                    font-weight: 700;
                    text-decoration: underline;
                }
            }
            @media (max-width: 767px) {
                margin-top: 24px;
                font-size: 14px;
                line-height: 32px;
            }
            a {
                font-weight: 700;
                color: $main-color;
            }
        }
    }
    &__sticky {
        // padding-bottom: 100px;
        & > div:not(:first-child) {
            position: relative;
            z-index: 1;
        }
        @media (max-width: 991px) {
            padding-bottom: 50px;
        }
        @media (max-width: 767px) {
            padding-bottom: unset;
        }
        &-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 72px);
        }
        position: relative;
    }
    &__top {
        position: relative;
        display: flex;
        justify-content: space-between;
        &-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 238px;
            z-index: -1;
            object-fit: cover;
            @media (max-width: 991px) {
                height: 172px;
            }
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
    &__communication {
        position: sticky;
        top: 318px;
        right: 42px;
        bottom: 100px;
        z-index: 2;
        margin-left: auto;
        max-width: 444px;
        width: 100%;
        @media (max-width: 1750px) {
            max-width: 300px;
        }
        @media (max-width: 1440px) {
            display: none;
        }
        &-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-transform: uppercase;
            color: $main-color;
        }
        &-flag {
            margin-top: 48px;
            width: 24px;
            height: 24px;
        }
        &-adress {
            margin-top: 24px;
            display: block;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            display: flex;
            align-items: flex-end;
            color: $main-color;
        }
        &-line {
            height: 1px;
            background-color: #333333;
            margin: 40px 0;
        }
        &-phone, &-mail {
            display: block;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            color: $main-color;
        }
        &-mail {
            margin-top: 24px;
        }
    }
    &__directions {
        padding: 72px 0;
        background: #FDFDF3;
        @media (max-width: 991px) {
            padding: 50px 0;
        }
        @media (max-width: 767px) {
        }
        &-block {
            cursor: none;
            @media (max-width: 991px) {
                display: none;
            }
        }
        &-content {
            padding: 24px;
            background: #FDF8ED;
            @media (max-width: 991px) {
                display: none;
            }
        }
        &-wrapper {
            margin-top: 56px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            @media (max-width: 991px) {
                grid-template-columns: 1fr;
            }
            @media (max-width: 767px) {
                margin-top: 24px;
            }
            .directions__block {
                &-top {
                    padding: 32px 48px;
                    img {
                        display: none;
                    }
                    &-title {
                        font-size: 32px;
                        line-height: 45px;
                        @media (max-width: 767px) {
                            font-size: 28px;
                            line-height: 39px;
                        }
                    }
                }
                &-item  {
                    position: relative;
                    &-active {
                        .directions__block-top {
                            background-color: #FDF8ED;
                        }
                    }
                    // border-bottom: unset;
                    &::before {
                        content: '';
                        opacity: 0;
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 4px;
                        background-color: #FFFFFF;
                        transition: all .3s ease;
                    }
                    &-active {
                        // background-color: #131313;
                        &::before {
                            opacity: 1;
                        }
                    }
                }
                &-list {
                    margin-top: unset;
                    li {
                        color: $main-color;
                        font-size: 18px;
                        &::before {
                            top: 22px;
                        }
                        @media (max-width: 991px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}