.home {
    padding-top: $header-desktop-height;
    height: var(--app-height);
    position: relative;
    z-index: 2;
    // background: url('../../assets/img/home-bg.png') center center/cover no-repeat;
    background: 
        // linear-gradient(6deg, #FFFFF0 0%, rgba(255, 255, 240, 0.00) 36.36%),
        // linear-gradient(4deg, #FFFFF0 0%, rgba(255, 255, 240, 0.00) 36.36%),
        // linear-gradient(0deg, rgba(255, 248, 231, 0.50) 0%, rgba(255, 248, 231, 0.00) 36.36%),
        linear-gradient(14.06deg, #FFFFF0 8.64%, rgba(255, 255, 240, 0) 48.65%),
        linear-gradient(20.96deg, #FFFFF0 20.33%, rgba(255, 255, 240, 0) 54.09%),
        linear-gradient(0deg, rgba(255, 248, 231, 0.5) 0%, rgba(255, 248, 231, 0) 36.36%);


        
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    @media (max-width: 576px) {
        padding-top: $header-mobile-height;
        // background: url('../../assets/img/home-bg-mob.jpg') center center/cover no-repeat;
    }
    &__bg {
        &-mob {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            @media (max-width: 767px) {
                display: block;
            }
        }
    }
    &__wrapper {
        padding: 0 48px;
        @media (max-width: 576px) {
            padding: 0 24px;
        }
    }
    &__block {
        position: relative;
        max-height: 107px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $line-color;
        @media (max-width: 767px) {
            height: 84px;
        }
        &-city {
            height: 100%;
            padding: 0 56px 0 48px;
            display: flex;
            align-items: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 102.5%;
            color: $main-color;
            border-right: 1px solid $line-color;
            @media (max-width: 767px) {
                width: 100%;
                justify-content: center;
                font-size: 14px;
                border-right: unset;
            }
            img {
                width: 20px;
                height: 20px;
                margin: 0 10px;
            }
            span {
                font-weight: 800;
                font-size: 15px;
                color: $secondary-color;
                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }
        &-links {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            @media (max-width: 767px) {
                display: none;
            }
        }
        &-scroll {
            padding: 0 48px 0 56px;
            height: 100%;
            display: flex;
            align-items: center;
            border-left: 1px solid $line-color;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 15px;
            line-height: 102.5%;
            color: $secondary-color;
            @media (max-width: 767px) {
                display: none;
            }
            svg {
                width: 32px;
                height: 32px;
                margin-right: 17px;
                & path {
                    fill : $secondary-color;
                }
            }
        }
    }
    &__title {
        margin-bottom: 32px;
        max-width: 1400px;
        font-family: 'Benzin';
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        line-height: 72px;
        text-transform: uppercase;
        color: $secondary-color;
        @media (max-width: 1600px) {
            font-size: 56px;
            line-height: 66px;
        }
        @media (max-width: 1199px) {
            font-size: 46px;
            line-height: 60px;
        }
        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 50px;
        }
        @media (max-width: 576px) {
            font-size: 26px;
            line-height: 40px;
            margin-bottom: 14px;
        }
    }
    &__subtitle {
        margin-bottom: 56px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 200%;
        color: $main-color;
        @media (max-width: 1199px) {
            font-size: 18px;
            line-height: 160%;
        }
        @media (max-width: 991px) {
            font-size: 16px;
            margin-bottom: 44px;
        }
        @media (max-width: 576px) {
            font-size: 14px;
            line-height: 32px;
            margin-bottom: 32px;
        }
    }
}
